<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white">
    <header>
      <Popover class="relative bg-white">
        <div class="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div class="flex justify-start lg:w-0 lg:flex-1">
            <router-link to="/">
              <span class="sr-only">Newaya</span>
              <img class="h-8 w-auto sm:h-10" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/flatlogoCapitalizedPNG-min.png" alt="" style="image-rendering: -webkit-optimize-contrast;"/>
            </router-link>
          </div>
          <div class="-mr-2 -my-2 md:hidden">
            <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-400">
              <span class="sr-only">Open menu</span>
              <MenuIcon class="h-6 w-6" aria-hidden="true" />
            </PopoverButton>
          </div>
          <PopoverGroup as="nav" class="hidden md:flex space-x-10">
            <tailwind-flyout />
            <a v-for="item in navigation" :href="item.href" :key="item.name" class="text-base font-medium text-gray-500 hover:text-gray-900">
              {{ item.name }}
            </a>
            <resale-flyout />
          </PopoverGroup>
          <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <!-- <a href="https://app.newaya.com/signin" class="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
              Sign in
            </a> -->
            <a href="https://meetings.hubspot.com/adamtaylornewaya/newayatradedemo" class="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orange-400 hover:bg-orange-600">
              Schedule Demo
            </a>
          </div>
        </div>

        <transition enter-active-class="duration-200 ease-out" enter-from-class="opacity-0 scale-95" enter-to-class="opacity-100 scale-100" leave-active-class="duration-100 ease-in" leave-from-class="opacity-100 scale-100" leave-to-class="opacity-0 scale-95">
          <PopoverPanel focus class="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
            <div class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div class="pt-5 pb-6 px-5">
                <div class="flex items-center justify-between">
                  <div>
                    <img class="h-8 w-auto" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/flatlogoCapitalizedPNG-min.png" alt="Workflow" />
                  </div>
                  <div class="-mr-2">
                    <PopoverButton class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-orange-400">
                      <span class="sr-only">Close menu</span>
                      <XIcon class="h-6 w-6" aria-hidden="true" />
                    </PopoverButton>
                  </div>
                </div>
                <div class="mt-6">
                  <nav class="grid grid-cols-1 gap-7">
                    <a v-for="item in solutions" :key="item.name" :href="item.to" class="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50">
                      <div class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-orange-400 text-white">
                        <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                      </div>
                      <div class="ml-4 text-base font-medium text-gray-900">
                        {{ item.name }}
                      </div>
                    </a>
                  </nav>
                </div>
              </div>
              <div class="py-6 px-5">
                <div class="mt-6">
                  <a href="https://app.newaya.com/for_sale" class="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-orange-400 hover:bg-orange-600">
                    Resale
                  </a>
                  <p class="mt-6 text-center text-base font-medium text-gray-500">
                    Existing customer?
                    <a href="https://app.newaya.com/signin" class="text-gray-900">
                      Sign in
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
    </header>
  </div>
  <link rel="preload" as="style" href="https://glass.io/integration/glass.min.css" onload="this.onload=null;this.rel='stylesheet'" />
</template>

<script>
import { Popover, PopoverButton, PopoverGroup, PopoverPanel, MenuItem } from '@headlessui/vue'
import {
  CurrencyDollarIcon,
  PhoneIcon,
  DesktopComputerIcon,
  ChatAlt2Icon,
  WifiIcon,
  MenuIcon,
  AcademicCapIcon,
  XIcon,
  BriefcaseIcon,
} from '@heroicons/vue/outline'
import { ChevronDownIcon } from '@heroicons/vue/solid'
import TailwindFlyout from '../components/TailwindFlyout.vue'
import ResaleFlyout from '../components/ResaleFlyout.vue'

const solutions = [
  {
    name: 'Wireless Carriers',
    description: 'Software and logistics to operate a competitive trade-in program.',
    to: '/carrier-tradein-program',
    icon: WifiIcon,
  },
  {
    name: 'ITAD/ITAM',
    description: 'Quick and secure.  Newaya purchases retired devices in bulk.',
    to: 'itam-itad-tradein',
    icon: DesktopComputerIcon,
  },
  { name: 'Schools', 
    description: "Newaya buys old iPads and Chromebooks when schools upgrade.", 
    to: '/school-tradein', 
    icon: AcademicCapIcon },
]
const navigation = [
  { name: "Webinars", href: '/webinars' },
  { name: "News", href: '/news' },
  // { name: 'About', href: '/about' },
]

const callsToAction = [
  {
    name: "Schedule a demo", 
    href: "https://meetings.hubspot.com/adamtaylornewaya/newayatradedemo", 
    icon: PhoneIcon,
  },
  {
    name: "Get quote", 
    href: "/get-tradein-quote", 
    icon: CurrencyDollarIcon,
  }
]

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    MenuItem,
    TailwindFlyout,
    ResaleFlyout,
    ChevronDownIcon,
    MenuIcon,
    XIcon,
  },
  setup() {
    return {
      solutions,
      navigation,
      callsToAction,
      accept: (close) => {
        close()
      },
    }
  },
}
  window.glassApiKey = 'LkfQEEPDhbO1V8xt5kOhsQtt';
  var s = document.createElement("script");
  s.type = "text/javascript";
  s.async = true;
  s.src = "https://glass.io/integration/glass.min.js";
  function load() {
    Glass.init();
  }
  s.onload = load;
  document.head.appendChild(s);
</script>