<template>
    <div class="bg-white">
        <div class="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:py-32 lg:px-8 lg:flex lg:items-center">
        <div class="lg:w-0 lg:flex-1">
            <h2 class="text-3xl font-extrabold text-gray-900 sm:text-4xl">Read the Viaero Case Study</h2>
            <p class="mt-3 max-w-3xl text-lg text-gray-500">Viaero has been running Newaya's trade-in program since 2015.  Learn how they leverage trade-in to compete with tier-1 providers locally.</p>
        </div>
        <div class="mt-8 lg:mt-0 lg:ml-8">
            <form method="get" action="https://8090484.fs1.hubspotusercontent-na1.net/hubfs/8090484/NewayaViaero%20Case%20Study.pdf" target="_blank" class="sm:flex">
            <label for="email-address" class="sr-only">Email address</label>
            <input id="email-address" name="email-address" type="email" autocomplete="email" required="" class="w-full px-5 py-3 border border-gray-300 shadow-sm placeholder-gray-400 focus:ring-1 focus:ring-orange-400 focus:border-orange-400 sm:max-w-xs rounded-md" placeholder="Enter your email" />
            <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
                <button type="submit" class="w-full flex items-center justify-center py-3 px-5 border border-transparent text-base font-medium rounded-md text-white bg-orange-400 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-400">Download PDF</button>
            </div>
            </form>
        </div>
        </div>
    </div>
</template>