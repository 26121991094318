import { createApp } from 'vue';
import App from './App.vue';
import './assets/tailwind.css';
import router from './router'
import VueWriter from "vue-writer";

createApp(App)
.use(router)
.use(VueWriter)
.mount('#app');
