import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Newaya | Trade-in solutions for wireless carriers, ITAD, schools, and businesses.'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: 'Newaya | About'
    }
  },
  {
    path: '/wholesale',
    name: 'Wholesale',
    component: () => import(/* webpackChunkName: "wholesale" */ '../views/Wholesale.vue'),
    meta: {
      title: 'Newaya | Wholesale'
    }
  },
  {
    path: '/grading',
    name: 'Grading',
    component: () => import(/* webpackChunkName: "grading" */ '../views/Grading.vue'),
    meta: {
      title: 'Newaya | Grading'
    }
  },
  {
    path: '/returns',
    name: 'Returns',
    component: () => import(/* webpackChunkName: "returns" */ '../views/Returns.vue'),
    meta: {
      title: 'Newaya | Returns'
    }
  },
  {
    path: '/school-tradein',
    name: 'School Trade-in',
    component: () => import(/* webpackChunkName: "school-tradein" */ '../views/SchoolTradein.vue'),
    meta: {
      title: 'Newaya | Trade-in for schools'
    }
  },
  {
    path: '/itam-itad-tradein',
    name: 'ITAM/ITAD Trade-in',
    component: () => import(/* webpackChunkName: "school-tradein" */ '../views/ItamItadTradein.vue'),
    meta: {
      title: 'Newaya | Trade-in for IT Asset Managers'
    }
  },
  {
    path: '/business-tradein',
    name: 'Business Trade-in',
    component: () => import(/* webpackChunkName: "business-tradein" */ '../views/BusinessTradein.vue'),
    meta: {
      title: 'Newaya | Trade-in for businesses'
    }
  },
  {
    path: '/carrier-tradein-program',
    name: 'Carrier Trade-in Program',

    component: () => import(/* webpackChunkName: "carrier-tradein-program" */ '../views/CarrierTradeinProgram.vue'),
    meta: {
      title: 'Newaya | Trade-in for wireless cellular carriers'
    }
  },
  {
    path: '/ecommerce-tradein-program',
    name: 'ecommerce Trade-in Program',

    component: () => import(/* webpackChunkName: "ecommerce-tradein-program" */ '../views/EcommerceTradeinProgram.vue'),
    meta: {
      title: 'Newaya | Trade-in for e-commerce'
    }
  },
  {
    path: '/webinars',
    name: 'Tradein Webinars',

    component: () => import(/* webpackChunkName: "tradein-webinars" */ '../views/Webinars.vue'),
    meta: {
      title: 'Newaya | Trade-in for wireless cellular carriers'
    }
  },
  {
    path: '/news',
    name: 'Newaya News',

    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
    meta: {
      title: 'Newaya | News'
    }
  },
  {
    path: '/get-tradein-quote',
    name: 'Get Trade-in Quote',

    component: () => import(/* webpackChunkName: "get-tradein-quote" */ '../views/GetTradeinQuote.vue'),
    meta: {
      title: 'Newaya | Get trade-in quote'
    }
  },
  {
    path: '/get-bulk-tradein-quote',
    name: 'Get Bulk Trade-in Quote',

    component: () => import(/* webpackChunkName: "get-bulk-tradein-quote" */ '../views/GetBulkTradeinQuote.vue'),
    meta: {
      title: 'Newaya | Get bulk trade-in quote'
    }
  },
  
  {
    path: '/for_sale', 
    beforeEnter(to, from, next) {
      window.location.href = "https://app.newaya.com/for_sale";
  }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from) => {
  window.scrollTo(0, 0)
})

export default router