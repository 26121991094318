<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="relative bg-white pt-16 pb-32 overflow-hidden">
    <div class="relative">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            <div>
              <span class="h-12 w-12 rounded-md flex items-center justify-center bg-orange-400">
                <WifiIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </span>
            </div>
            <div class="mt-6">
              <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                For Wireless Carriers
              </h2>
              <p class="mt-4 text-lg text-gray-500">
                Newaya's plug-and-play tradein program helps carriers run promotions that compete with Tier-1 providers.
              </p>
              <div class="mt-6">
                <a href="/carrier-tradein-program" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-400 hover:bg-orange-600">
                Carrier Trade-in Solutions
                </a>
              </div>
            </div>
          </div>
          <div class="mt-8 border-t border-gray-200 pt-6">
            <blockquote>
              <div>
                <p class="text-base text-gray-500">
                  &ldquo;We started using the program the day our staff was trained. The training took literally 30 minutes and the setup was virtually seamless.&rdquo;
                </p>
              </div>
              <footer class="mt-3">
                <div class="flex items-center space-x-3">
                  <div class="flex-shrink-0">
                    <img class="h-6 w-6 rounded-full" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/JoshEckert+smaller-min.png" alt="" />
                  </div>
                  <div class="text-base font-medium text-gray-700">
                    Josh Eckert, Inland Cellular
                  </div>
                </div>
              </footer>
            </blockquote>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0">
          <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img class="w-full rounded-xl lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/tradein-min.png" alt="NewayaTrade user interface" />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-24">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
          <div>
            <div>
              <span class="h-12 w-12 rounded-md flex items-center justify-center bg-orange-400">
                <ShieldExclamationIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </span>
            </div>
            <div class="mt-6">
              <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                For Damaged Devices
              </h2>
              <p class="mt-4 text-lg text-gray-500">
                Newaya's serialized resale model captures the highest possible value for damaged devices.  Returning value to customers and keeping devices out of landfills.
              </p>
              <div class="mt-6">
                <a href="/get-bulk-tradein-quote" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-400 hover:bg-orange-600">
                  Sell broken devices now
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
          <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img class="w-full rounded-xl lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/damaged.png" alt="Customer profile user interface" />
          </div>
        </div>
      </div>
    </div>
    <div class="relative bg-white pt-16 pb-32 overflow-hidden">
    <div class="relative">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-16 lg:max-w-none lg:mx-0 lg:px-0">
          <div>
            <div>
              <span class="h-12 w-12 rounded-md flex items-center justify-center bg-orange-400">
                <DesktopComputerIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </span>
            </div>
            <div class="mt-6">
              <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                For IT Asset Managers
              </h2>
              <p class="mt-4 text-lg text-gray-500">
                Newaya purchases entire fleets of devices in any condition when IT Asset Managers upgrade their departments. <b>Get free shipping, secure data erasure, and serialized reports on every batch</b>.
              </p>
              <div class="mt-6">
                <a href="/itam-itad-tradein" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-400 hover:bg-orange-600">
                ITAD Trade-in
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0">
          <div class="pl-4 -mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img class="w-full rounded-xl lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/itad.png" alt="Tradein for schools" />
          </div>
        </div>
      </div>
    </div>
  </div>
    <div class="mt-24">
      <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
        <div class="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
          <div>
            <div>
              <span class="h-12 w-12 rounded-md flex items-center justify-center bg-orange-400">
                <AcademicCapIcon class="h-6 w-6 text-white" aria-hidden="true" />
              </span>
            </div>
            <div class="mt-6">
              <h2 class="text-3xl font-extrabold tracking-tight text-gray-900">
                For Schools
              </h2>
              <p class="mt-4 text-lg text-gray-500">
                Upgrading iPads or Chromebooks?  Newaya will purchase all of your school's old devices -- even the <b>really broken</b> ones.
              </p>
              <div class="mt-6">
                <a href="/school-tradein" class="inline-flex px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-400 hover:bg-orange-600">
                School Trade-in
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-12 sm:mt-16 lg:mt-0 lg:col-start-1">
          <div class="pr-4 -ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
            <img class="w-full rounded-xl lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/schools-min.png" alt="Trade-in for ITAM/ITAD" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WifiIcon, DesktopComputerIcon, AcademicCapIcon, ShieldExclamationIcon, SparklesIcon } from '@heroicons/vue/outline'

export default {
  components: {
    ShieldExclamationIcon,
    AcademicCapIcon,
    DesktopComputerIcon,
    WifiIcon,
    SparklesIcon,
  },
}
</script>

